body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: black;
  background: white;
}

p {
  @media (max-width: 1280px) {
    font-size: 14px;
  }
}

.font2 {
  font-family: $headings-font-family;
}

.page-header {
  margin: 50px 0;
  @extend .title_center;
}

.bg-1 {
  @media (max-width: $screen-sm-min) {
    h1 {
      text-shadow: 2px 2px 1px black, 0px 0px 10px black;
    }
  }
}

.btn,
a,
button {
  transition: 0.5s;
  outline-style: none;
  outline-width: 0;
  outline-color: transparent;

  &:hover,
  &:focus,
  &:active {
    outline-style: none;
    outline-width: 0;
    outline-color: transparent;
  }
}

.btn {
  line-height: 1.5 !important;
}

.footer {
  text-align: center;
  background-color: white;
  font-size: $font-size-base;
  color: black;
  min-height: 25rem;
  display: flex;
  align-items: center;
  border-top: solid 1px $brand-primary;

  a {
    color: black;

    &:hover {
      color: black;
    }
  }

  .footer_address {
    color: black;
  }
  @media (min-width: $screen-sm-min) {
    text-align: left;
  }
}

////////////// boutons

.btn-fleche {
  @extend .btn-accueil;
  background: $brand-primary;
  white-space: normal;
  position: relative;
  padding: 10px 50px;
  border: 2px solid;
  color: white;
  font-weight: bold;
  border-radius: 50px;
  border: solid 1px $brand-primary;
  //margin-bottom: 50px;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  &:hover {
    background: white;
    color: $brand-primary;
    border-radius: 50px;
    border: solid 1px $brand-primary;
    padding-right: 70px;
  }

  &::after {
    position: absolute;
    margin-left: 20px;
    content: "\f061";
    font-family: fontAwesome;
    color: $brand-primary;
    -webkit-transition: all 0.8s ease-out 0s;
    transition: all 0.8s ease-out 0s;
  }
}

.btn-fleche-2 {
  @extend .btn-accueil;
  background: white;
  white-space: normal;
  position: relative;
  padding: 10px 50px;
  border: 2px solid;
  color: $brand-primary;
  font-weight: bold;
  border-radius: 50px;
  border: solid 1px $brand-primary;
  //margin-bottom: 50px;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  &:hover {
    background: $brand-primary;
    color: white;
    border-radius: 50px;
    border: solid 1px $brand-primary;
    padding-right: 70px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }

  &::after {
    position: absolute;
    margin-left: 20px;
    content: "\f061";
    font-family: fontAwesome;
    color: white;
    -webkit-transition: all 0.8s ease-out 0s;
    transition: all 0.8s ease-out 0s;
  }
}

.btn-fleche-inverse {
  @extend .btn-fleche;
  border: 2px solid;
  color: $brand-primary;
  border: solid 1px $brand-primary;

  &:hover {
    background: $brand-primary;
    color: white;
  }

  &::after {
    color: white;
  }
}

.btn-perso {
  @extend .btn-accueil;
  background: $brand-primary;
  color: white;
  border: solid 2px white;
  border-radius: 50px;
  padding: 15px 50px;
  margin: 15px auto;

  &:hover {
    background: white;
    color: $brand-primary;
    border-radius: 50px;
    border: solid 2px $brand-primary;
  }

  .fa {
    margin-right: 10px;
  }
}

.btn-perso-inverse {
  background: white;
  color: $brand-primary;
  border: solid 1px $brand-primary;
  border-radius: 50px;
  padding: 15px 50px;
  margin: 15px auto;

  .fa {
    margin-right: 10px;
  }
}

/* ==========================================================================
      Global
      ========================================================================== */
.title_left {
  @extend .text-left;
  margin: 0 0 20px 0;
  padding: 0 0 25px 0;
  position: relative;
  color: $brand-primary;
  text-transform: uppercase;
  line-height: 1.5;
  // font-family: "Josefin Sans", sans-serif;
  font-weight: bolder;

  small {
    display: block;
    line-height: 1.5;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 4rem;
    height: 4px;
    background-color: $brand-primary;
  }
}

.title_left_2 {
  @extend .text-left;
  @extend .title_left;
  color: white;
  font-weight: bolder;

  &::after {
    background-color: black;
  }
}

.title_center {
  @extend .text-center;
  margin: 0 0 20px 0;
  padding: 0 0 25px 0;
  position: relative;
  color: $brand-primary;
  line-height: 1.5;
  // font-family: "Josefin Sans", sans-serif;

  small {
    display: block;
    line-height: 1.5;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 4rem;
    height: 2px;
    transform: translateX(-50%);
    background-color: $brand-primary;
  }
}

.frame-1 {
  padding: 20px;
  margin: 20px;

  @media (max-width: $screen-xs-min) {
    padding: 10px;
    margin: 10px;
  }
}

.frame-2 {
  padding: 20px;

  @media (max-width: $screen-xs-min) {
    padding: 10px;
  }
}

.frame-3 {
  margin: 20px;

  @media (max-width: $screen-xs-min) {
    margin: 10px;
  }
}

.smap {
  .map {
    padding-bottom: 500px;
  }

  .block1 {
    padding: 3%;
    color: white;
    background: $brand-primary;
  }
}

.img-rounded {
  border-radius: 15px;
}

.blockquote-2 {
  padding: 15px 30px;
  margin: 0 0 30px;
  font-size: 16px;
  border-left: 5px solid $brand-primary;
  margin-left: 25px;
  background-color: white;
  border-radius: 0 15px 15px 0px;
  //   box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}

.reverse {
  @media (max-width: $screen-sm-min) {
    flex-direction: column-reverse;
  }
}

@media (min-width: $screen-md-min) {
  .figure__compo-1 {
    position: relative;
    @include padding(1rem);
    height: 40rem;
    max-height: 100%;
    width: 40rem;
    max-width: 100%;

    > img {
      position: absolute;
      object-fit: cover;
    }

    > img:nth-child(1) {
      top: 0;
      left: 0;
      width: 70%;
      height: 55%;
    }

    > img:nth-child(2) {
      bottom: 0;
      right: 0;
      width: 70%;
      height: 55%;
    }
  }
}

.last {
  border: solid 2px $brand-primary;
  border-radius: 100px;
  a {
    font-weight: 500 !important;
  }
}

//.nav>li>a {
//padding: 10px 15px;

//	@media (max-width:1280px) {
//	font-size: 12px;
//	padding: 10px 10px;
//}
//}

hr {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 0;
  border-top: 1px solid rgba(black, 0.2);
}

@media (max-width: 992px) {
  .rellax {
    transform: translate3d(0, 0, 0) !important;
  }
}

//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////

.s2 {
  .col-heading-titre {
    min-height: 80vh;
    position: relative;
    border: solid 1.5rem $brand-second;
    padding: 5%;
    background-color: rgba(black, 0.3);
    z-index: 2;
  }

  .figure-parallax {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .titre-main {
    color: white;
    position: relative;
    z-index: 10;
    margin: 0 0 3rem;
    text-shadow: 0 0 1.5rem rgba(black, 0.9);
    font-weight: bolder;

    @media (min-width: $screen-md-min) {
      font-size: 2.5rem;
      line-height: 1.5;
    }

    > small {
      text-transform: none;
      font-weight: 400;
      color: white;
      @media (min-width: $screen-md-min) {
      }
    }
  }

  .btn-main {
    margin: 1rem 0;
    position: relative;
    z-index: 10;
  }

  .btn-titre {
    border: solid 2px white;
    color: white;
    font-size: 1.3rem;

    @media (min-width: $screen-sm-min) {
    }

    &:hover {
      background-color: white;
    }
  }

  .col-heading-text {
    padding: 5%;
  }

  .wrap-card-cat {
    padding: 1rem;
  }
}

.s3 {
  text-align: justify;

  .block1 {
  }

  .block2 {
    background: url("../images/charte/hivers.jpg") no-repeat center;
    background-size: cover;
    min-height: 30vh;
  }

  .block3 {
    background: url("../images/charte/printemps.jpg") no-repeat center;
    background-size: cover;
    min-height: 30vh;
  }

  .block4 {
    background: url("../images/charte/ete.jpg") no-repeat center;
    background-size: cover;
    min-height: 30vh;
  }

}
